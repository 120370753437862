<template>
  <div class="mt-5">
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="text-right"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('menu.perfilUser') | lowercase }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.name') }}
            </th>
            <th
              class="text-uppercase"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>{{ getTitle(iten) }}</td>
            <td>
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editItemTitle(iten)"
                  >
                    <v-icon>mdi-translate</v-icon>
                  </v-btn>
                </template>
                <span>{{ `${$t('menu.translate')} - ${$t('lbl.name')}` }}</span>
              </v-tooltip>
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editItemDescription(iten)"
                  >
                    <v-icon color="warning">
                      mdi-translate
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ `${$t('menu.translate')} - ${$t('lbl.description')}` }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      search: '',
      items: [],
      totalItems: 0,
      totalF: 0,
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
    }
  },
  created() {
    this.profile()
    this.load()
    this.loadAll()
  },
  methods: {
    getTitle(item) {
      if (this.$i18n.locale === 'es') {
        return item.name
      }

      return item[`name_${this.$i18n.locale}`]
    },
    getDescripcion(item) {
      if (this.$i18n.locale === 'es') {
        return item.description
      }

      return item[`description_${this.$i18n.locale}`]
    },
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
      if (this.user.nivel === -1) {
        this.is_nivel_propietario = true
      }
      this.isLoading = false
    },
    async load() {
      await this.axios
        .get(`perfil_afiliado?page=${this.pagination.current}&per_page=${this.itemsPerPage}&search=${this.search}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
    },
    async loadAll() {
      await this.axios
        .get('perfil_afiliado?per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    onPageChange() {
      this.load()
    },
    editItemTitle(item) {
      localStorage.setItem('traslate-perfil-id', item.id)
      this.$router.push({ name: 'translate-perfils-name' })
    },
    editItemDescription(item) {
      localStorage.setItem('traslate-perfil-id', item.id)
      this.$router.push({ name: 'translate-perfils-description' })
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
